import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: 'bold',
      color: 'heading'
    }} mdxType="Text">
  ようこそ。
    </Text>
    <p>{`I'm Lachlan — a Tokyo-based cloud security engineer and cybersecurity analyst. I currently work at `}<a parentName="p" {...{
        "href": "https://exawizards.com"
      }}>{`ExaWizards Inc.`}</a>{`, a company focused on solving social issues with AI. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      